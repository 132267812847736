import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () => import('@/views/Home/Home.vue')
const DetailsDt = () => import('@/views/detailsDt/detailsDt.vue')
const TheQueryDt = () => import('@/views/theQueryDt/theQueryDt.vue')
const DetailsWbdw = () => import('@/views/detailsWbdw/detailsWbdw.vue')
const TheQueryWbdw = () => import('@/views/theQueryWbdw/theQueryWbdw.vue')
const ScanTheCode = () => import('@/views/scanTheCode/scanTheCode.vue')
const NullData = () => import('@/views/nullData')
const LoadPage = () => import('@/views/loadPage')
const InitPage = () => import('@/views/scanElevator/initPage.vue')
const ScanElevator = () => import('@/views/scanElevator')
const Null = () => import('@/views/scanElevator/null.vue')
const maintenance = () => import('@/views/scanElevator/detail.vue')
const reply = () => import('@/views/scanElevator/reply.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/LoadPage',
    // name: 'LoadPage',
    // component: LoadPage,
    meta: {
      title: '电梯物联网云平台'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: '电梯物联网云平台'
    }
  },
  {
    path: '/detailsDt',
    name: 'DetailsDt',
    component: DetailsDt,
    meta: {
      keepAlive: false,
      title: '电梯详情'
    }
  },
  {
    path: '/theQueryDt',
    name: 'TheQueryDt',
    component: TheQueryDt,
    meta: {
      title: '电梯查询'
    }
  },
  {
    path: '/detailsWbdw',
    name: 'DetailsWbdw',
    component: DetailsWbdw,
    meta: {
      title: '维保单位查询'
    }
  },
  {
    path: '/theQueryWbdw',
    name: 'TheQueryWbdw',
    component: TheQueryWbdw,
    meta: {
      title: '维保单位详情'
    }
  },
  {
    path: '/scanTheCode',
    name: 'ScanTheCode',
    component: ScanTheCode,
    meta: {
      title: '扫码结果'
    }
  },
  {
    path: '/nullData',
    name: 'NullData',
    component: NullData,
    meta: {
      title: ''
    }
  },
  {
    path: '/LoadPage',
    name: 'LoadPage',
    component: LoadPage,
    meta: {
      title: ''
    }
  },

  {
    path: '/InitPage',
    name: 'InitPage',
    component: InitPage,
    meta: {
      title: ''
    }
  },

  {
    path: '/Null',
    name: 'Null',
    component: Null,
    meta: {
      title: ''
    }
  },

  // ScanElevator
  {
    path: '/ScanElevator',
    name: 'ScanElevator',
    component: ScanElevator,
    meta: {
      title: ''
    }
  },
  {
	  path: '/maintenance',
	  name: 'maintenance',
	  component: maintenance,
	  meta: {
	    title: '维保详情'
	  }
  },
  {
    path: '/reply',
    name: 'reply',
    component: reply,
    meta: {
      title: '乘梯安全知识'
    }
  }
]

const router = new VueRouter({
  base: '/pub/monitor/',
  mode: 'history',
  routes
})

export default router
