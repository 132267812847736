var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('router-view', {
    directives: [{
      name: "wechat-title",
      rawName: "v-wechat-title",
      value: _vm.$route.meta.title,
      expression: "$route.meta.title"
    }]
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };